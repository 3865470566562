





























































import { FixedNumber } from '@ethersproject/bignumber'
import { Observer } from 'mobx-vue'
import { Component, Vue, Inject } from 'vue-property-decorator'
import { MarketViewModel } from '../viewmodels/market-viewmodel'

@Observer
@Component({
  components: {},
})
export default class OpenMarket extends Vue {
  @Inject() vm!: MarketViewModel

  nftSoldHeaders = [
    { text: '#ID', value: 'id', sortable: false },
    { text: '', value: 'image', sortable: false },
    { text: 'Time', value: 'time', sortable: false },
    { text: 'Buyer', value: 'buyer', sortable: false },
    { text: 'Sale Price', value: 'salePrice', sortable: true },
  ]
}
